body{
  background-color: #02000e;
}

.App header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: rgb(11, 9, 160);
  padding: 0.5rem 1rem;
}

ul.menu {
  list-style: none;
}

ul.menu li {
  display: inline;
  padding: 12px;
}

ul.menu a {
  color: #ffffff;
}

.page {
  text-align: center;
}

button {
  background-color: #6200ee;
  color: #da1010;
  border: none;
  padding: 12px 16px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: #3700b3;
}

.warning {
  width: 800px;
  margin: 24px auto;
  border: 5px solid #ff0000;
  border-radius: 15px;
}

.warning h1 {
  margin-top: 0;
  color: white;
  font-size: 48px;
  line-height: 96px;
  background-color: #ff0000;
  text-transform: uppercase;
}

.warning h2 {
  font-size: 36px;
  text-transform: uppercase;
}

#companies-tablecontainer{
  overflow-x: unset;
}

